import React, { useEffect, useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectPayouts,
  ConnectDocuments,
} from "@stripe/react-connect-js";
import { auth } from "../firebase";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";

const StripeDashboard = () => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const user = auth.currentUser;

  useEffect(() => {
    const initializeStripeConnect = async () => {
      try {
        const fetchClientSecret = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_ASSET_URL}/create-account-session`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  uid: user.uid,
                }),
              }
            );

            if (!response.ok) {
              const errorData = await response.json();
              console.error("Error response from server:", errorData);
              throw new Error(
                `Failed to create account session: ${errorData.error}`
              );
            }

            const { clientSecret } = await response.json();
            console.log("Client secret received successfully");
            return clientSecret;
          } catch (error) {
            console.error("Error in fetchClientSecret:", error);
            throw error;
          }
        };

        const instance = await loadConnectAndInitialize({
          publishableKey:
            "pk_live_51O8m2FC4BUAyrHhVCDjVlVeq8ALviT4wyk2OuOtOzgeMY8SHPj9uUDN4tujwKAD06rk4rZH1ixJzdYjJRnrDZN4j00P0ARa2Ec", // Replace with your actual publishable key
          fetchClientSecret: fetchClientSecret,
          appearance: {
            // Customize the appearance if needed
          },
        });

        setStripeConnectInstance(instance);
      } catch (error) {
        console.error("Error initializing Stripe Connect:", error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    };

    if (user) {
      initializeStripeConnect();
    }
  }, [user]);

  if (!stripeConnectInstance) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <BreadcrumbPage>Finance Hub</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <p className="text-3xl m-3 text-start">Finance Hub</p>

      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="w-full max-w-4xl p-5 border rounded-lg shadow-lg">
          <p className="text-2xl m-3 text-start">Payments/Transactions</p>

          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectPayments />
          </ConnectComponentsProvider>
        </div>
        <div className="w-full max-w-4xl p-5 border rounded-lg shadow-lg mt-5">
          <p className="text-2xl m-3 text-start">Payouts</p>

          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectPayouts />
          </ConnectComponentsProvider>
        </div>
        <div className="w-full max-w-4xl p-5 border rounded-lg shadow-lg mt-5">
          <p className="text-2xl m-3 text-start">Documents</p>

          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectDocuments />
          </ConnectComponentsProvider>
        </div>
      </div>
    </div>
  );
};

export default StripeDashboard;
