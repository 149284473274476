import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Ensure axios is imported
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

const StoreProducts = () => {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && user.uid) {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/products/user/${user.uid}`,
            { withCredentials: true }
          );
          setProducts(response.data.products);
          console.log("Products fetched successfully:", response.data.products);
        } catch (error) {
          console.error("Failed to fetch products:", error);
        }
      };
      fetchProducts();
    }
  }, [user]);

  const handleDeleteProduct = async (productId) => {
    if (!user) {
      alert("You must be logged in to delete a product");
      return;
    }

    try {
      const idToken = await user.getIdToken();
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Firebase-UID": user.uid,
          },
        }
      );
      // Refresh the products list after deletion
      const updatedProducts = products.filter(
        (product) => product._id !== productId
      );
      setProducts(updatedProducts);
      alert("Product deleted successfully");
    } catch (error) {
      console.error("Failed to delete product:", error);
      alert("Failed to delete product");
    }
  };
  return (
    <div>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>
                <Link to="/st/inventory">Inventory Management</Link>
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="text-start text-2xl font-bold m-3">
        Inventory Management
      </div>
      <div className="container mx-auto px-4 md:px-6 py-8">
        <div className="flex items-center justify-between mb-6">
          <Link to="/st/addproduct">
            <Button>Add Product</Button>
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Image
                </th>
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Product
                </th>
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Price
                </th>
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Quantity
                </th>
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Status
                </th>
                <th className="px-4 py-3 text-left font-medium text-gray-700 dark:text-gray-300">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr
                  key={product._id}
                  className="border-b border-gray-200 dark:border-gray-700"
                >
                  <td className="px-4 py-3">
                    <img
                      src={product.imageUrls[0]}
                      alt={product.title}
                      width={40}
                      height={40}
                      className="rounded-md"
                    />
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex flex-col">
                      <span className="font-medium text-left">
                        {product.title}
                      </span>
                      <span className="text-gray-500 dark:text-gray-400 text-sm line-clamp-2 text-left">
                        {product.description}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-left">
                    <span className="text-primary font-medium">
                      ${product.price}
                    </span>
                  </td>
                  <td className="px-4 py-3 text-left">
                    <span>{product.quantity || "N.A"}</span>
                  </td>
                  <td className="px-4 py-3 text-left">
                    <Badge variant={product.status ? "success" : "danger"}>
                      {product.status ? "Activated" : "Deactivated"}
                    </Badge>
                  </td>
                  <td className="px-4 py-3 text-left">
                    <div className="flex gap-2">
                      <Link to={`/editproduct/${product._id}`}>
                        <Button variant="outline" size="sm">
                          Edit
                        </Button>
                      </Link>
                      <Button
                        variant="outline"
                        size="sm"
                        color="danger"
                        onClick={() => handleDeleteProduct(product._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StoreProducts;
