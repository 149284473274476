import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import Penguin from "./penguin.webp";

const Signup = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <h1 className="text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
          Coming Soon
        </h1>
        <p className="mt-4 text-muted-foreground">
          We're working hard to bring you something amazing. Check back soon :)
        </p>
      </div>
      <img
        src={Penguin}
        width={400}
        height={300}
        alt="Coming Soon"
        className="mt-6 rounded-lg"
        style={{ aspectRatio: "400/300", objectFit: "cover" }}
      />
    </div>
  );
};

export default Signup;
