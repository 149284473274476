import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../firebase"; // Adjust the path as necessary

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <div className="min-h-screen bg-white bg-dotted-pattern">
      <div className="pt-5 sm:pt-10 px-4 sm:px-10">
        <div className="text-left text-7xl sm:text-7xl md:text-8xl lg:text-9xl font-bold inline-block bg-white p-3 sm:p-5">
          <span className="block">Sell</span>
          <span className="block">locally</span>
          <span className="block">with</span>
          <span className="block">Spockal</span>
          <div className="flex space-x-4 mt-8">
            {isAuthenticated ? (
              <Button className="text-2xl py-6 px-10">
                <Link to="/st/dashboard">Go to Dashboard</Link>
              </Button>
            ) : (
              <>
                <Button className="text-2xl py-6 px-10">
                  <Link to="/auth/join">Join</Link>
                </Button>
                <Button className="text-2xl py-6 px-10">
                  <Link to="/auth/storelogin">Login</Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
