import logo from "./logo.svg";
import "./App.css";
import StoreNav from "navigation/storenav";
import { Route, Routes } from "../node_modules/react-router-dom/dist/index";
import Login from "auth/login";
import StoreProducts from "Ycomponents/storeProducts";
import AddProduct from "Ycomponents/addProduct";
import Orders from "Ycomponents/orders";
import Settings from "Ycomponents/settings";
import EditProduct from "Ycomponents/editProduct";
import Refresh from "Ycomponents/Refresh";
import Return from "Ycomponents/Return";
import Dashboard from "Ycomponents/dashboard";
import ProtectedRoute from "auth/protectedRoute";
import Home from "NAcomponents/home";
import StripeDashboard from "Ycomponents/stripeDashboard";
import Signup from "Ycomponents/Signup";
import PublicRoute from "auth/publicRoute";

function App() {
  return (
    <div className="App">
      <StoreNav />
      <Routes>
        <Route
          path="/auth/storelogin"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
          exact
        />
        <Route
          path="/auth/join"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
          exact
        />

        <Route
          path="/st/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home />} />
        <Route
          path="/st/inventory"
          element={
            <ProtectedRoute>
              <StoreProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/st/addproduct"
          element={
            <ProtectedRoute>
              <AddProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/st/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/st/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editproduct/:productId"
          element={
            <ProtectedRoute>
              <EditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/st/financehub"
          element={
            <ProtectedRoute>
              <StripeDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/refresh/:connectedAccountId"
          element={
            <ProtectedRoute>
              <Refresh />
            </ProtectedRoute>
          }
        />
        <Route
          path="/return/:connectedAccountId"
          element={
            <ProtectedRoute>
              <Return />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
