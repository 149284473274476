import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { CloudUploadIcon, XIcon, UploadIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Switch } from "@/components/ui/switch";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { auth } from "../firebase"; // Import auth from your firebase.js file

const EditProduct = () => {
  const { productId } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [currentImages, setCurrentImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [tags, setTags] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [quantity, setQuantity] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error("No authenticated user found");
        }
        const idToken = await user.getIdToken();
        const uid = user.uid;
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const { title, description, price, imageUrls, tags, status, quantity } =
          response.data.product;
        setTitle(title);
        setDescription(description);
        setPrice(price);
        setCurrentImages(imageUrls);
        setTags(tags.join(", "));
        setIsActive(status);
        setQuantity(quantity !== null ? quantity.toString() : "");
      } catch (error) {
        console.error("Failed to fetch product details:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleRemoveOldImage = (index) => {
    const imageToRemove = currentImages[index];
    const updatedCurrentImages = currentImages.filter(
      (_, idx) => idx !== index
    );
    setCurrentImages(updatedCurrentImages);
    setRemovedImages([...removedImages, imageToRemove]);
  };

  const handleRemoveNewImage = (index) => {
    const updatedNewImages = newImages.filter((_, idx) => idx !== index);
    setNewImages(updatedNewImages);
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const imageObjects = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setNewImages([...newImages, ...imageObjects]);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }
      const idToken = await user.getIdToken();
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/products/status/${productId}`,
        { status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
            "Firebase-UID": user.uid, // Add this line
          },
        }
      );
      alert("Product status updated successfully!");
    } catch (error) {
      console.error("Failed to update product status:", error);
      alert("Failed to update product status.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append(
      "tags",
      JSON.stringify(tags.split(",").map((tag) => tag.trim()))
    );
    formData.append("status", isActive);
    formData.append("removeImages", JSON.stringify(removedImages));
    if (quantity === "" || quantity === null) {
      formData.append("quantity", "");
    } else {
      formData.append("quantity", quantity);
    }
    newImages.forEach((image) => {
      formData.append("newImages", image.file, image.file.name);
    });

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }
      const idToken = await user.getIdToken();
      const uid = user.uid;
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${idToken}`,
            "Firebase-UID": uid,
          },
        }
      );
      console.log("Update successful:", response.data);
      navigate("/st/inventory");
    } catch (error) {
      console.error("Failed to update product:", error);
    }
  };

  const handleCancel = () => {
    navigate("/st/inventory");
  };

  return (
    <div>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/inventory">Inventory Management</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Edit Product</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="grid gap-6 max-w-2xl mx-auto">
        <p className="text-3xl m-3">Edit Product</p>
        <div className="grid gap-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter product title"
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter product description"
            rows={4}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="price">Price</Label>
          <Input
            id="price"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter product price"
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="quantity">Quantity</Label>
          <Input
            id="quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Enter product quantity (optional)"
          />
        </div>
        {/* Image sections (current and new) remain the same */}
        <div className="grid gap-2">
          <Label htmlFor="tags">Tags</Label>
          <Input
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Enter product tags (separated by commas)"
          />
        </div>
        <div className="flex items-center justify-between">
          <Label htmlFor="active" className="flex items-center gap-2">
            <Switch
              id="active"
              checked={isActive}
              onCheckedChange={(checked) => {
                setIsActive(checked);
                handleStatusChange(checked);
              }}
            />
            Active
          </Label>
          <div className="flex gap-2">
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
