// login.js
import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Fingerprint } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import axios from "axios";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const loginSubmitHandler = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    try {
      // Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Fetch storeId from your backend
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/stores/login`,
        {
          firebaseUid: user.uid,
          email: email, // Add this line
        }
      );

      if (response.data && response.data.storeId) {
        localStorage.setItem("storeId", response.data.storeId);
        console.log("Login successful");
        navigate("/st/dashboard");
      } else {
        setError("Failed to fetch store information");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center py-12  ">
      <div className="m-4">
        <div className="mx-auto w-full max-w-md space-y-8">
          <div>
            <Link href="#" className="flex justify-center" prefetch={false}>
              <Fingerprint className="h-8 w-8" />
              <span className="sr-only">Spockal</span>
            </Link>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50">
              Sign in to your account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
              Enter your username and password to access your account
            </p>
            {/* <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
              Or{" "}
              <Link
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-400"
                prefetch={false}
              >
                sign up for a new account
              </Link>
            </p> */}
          </div>
          <form className="space-y-6" onSubmit={loginSubmitHandler}>
            <div>
              <Label htmlFor="username" className="sr-only">
                Username
              </Label>
              <Input
                id="username"
                name="email" // Change this to match what the login handler expects
                type="text"
                autoComplete="username"
                required
                placeholder="Username"
                className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-indigo-500 rounded-xl"
              />
            </div>
            <div>
              <Label htmlFor="password" className="sr-only">
                Password
              </Label>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="Password"
                className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-indigo-500 rounded-xl"
              />
            </div>
            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox
                  id="remember-me"
                  name="remember-me"
                  className="rounded"
                />
                <Label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900 dark:text-gray-50"
                >
                  Remember me
                </Label>
              </div>
              <div className="text-sm">
                <Link
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-400 "
                  prefetch={false}
                >
                  Forgot your password?
                </Link>
              </div>
            </div> */}
            <div>
              <Button
                type="submit"
                className="w-full rounded-xl"
                // disabled={!formState.isValid}
              >
                Sign in
              </Button>
            </div>
          </form>
          {/* <div className="google_re">
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy" target="blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://policies.google.com/terms" target="blank">
              Terms of Service
            </a>{" "}
            apply.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
