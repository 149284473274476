import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  PackageIcon,
  PlusIcon,
  SettingsIcon,
  ShoppingCartIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { DollarSign } from "lucide-react";

const Dashboard = () => {
  return (
    <div>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>Login</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="text-start text-2xl font-bold m-3">Dashboard</div>
      <div className="grid grid-cols-1 gap-6 p-4 md:grid-cols-2 lg:grid-cols-4 lg:p-6">
        <Card className="flex flex-col">
          <CardHeader className="flex items-center gap-4 pb-4">
            <PackageIcon className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Inventory</CardTitle>
              <CardDescription>
                View and manage your product inventory.
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="flex-1 flex items-center justify-center">
            <Button size="lg">
              <Link to="/st/inventory">Manage Inventory</Link>
            </Button>
          </CardContent>
        </Card>
        <Card className="flex flex-col">
          <CardHeader className="flex items-center gap-4 pb-4">
            <PlusIcon className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Add Product</CardTitle>
              <CardDescription>
                Create a new product for your store.
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="flex-1 flex items-center justify-center">
            <Link to="/st/addproduct">
              <Button size="lg">Add Product</Button>
            </Link>
          </CardContent>
        </Card>
        <Card className="flex flex-col">
          <CardHeader className="flex items-center gap-4  pb-4">
            <ShoppingCartIcon className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Orders</CardTitle>
              <CardDescription>
                View and manage your customer orders.
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="flex-1 flex items-center justify-center">
            <Link to="/st/orders">
              <Button size="lg">Manage Orders</Button>
            </Link>
          </CardContent>
        </Card>
        <Card className="flex flex-col">
          <CardHeader className="flex items-center gap-4  pb-4">
            <SettingsIcon className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Settings</CardTitle>
              <CardDescription>
                Customize your store preferences.
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="flex-1 flex items-center justify-center">
            <Link to="/st/settings">
              <Button size="lg">Open Settings</Button>
            </Link>
          </CardContent>
        </Card>
        <Card className="flex flex-col">
          <CardHeader className="flex items-center gap-4  pb-4">
            <DollarSign className="h-8 w-8 text-primary" />
            <div>
              <CardTitle>Finance Hub</CardTitle>
              <CardDescription>
                Check your store sales, orders, and finances
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="flex-1 flex items-center justify-center">
            <Link to="/st/financehub">
              <Button size="lg">Launch Finance</Button>
            </Link>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
