import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { DownloadIcon, PrinterIcon, TruckIcon } from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import soundBite from "./../notif_sound.mp3";

// orders.js web application

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const lastPollTimeRef = useRef(new Date().toISOString());
  const orderIdsRef = useRef([]);
  const printFrameRef = useRef(null);
  const [printedOrders, setPrintedOrders] = useState(new Set());
  const fullScreenDivRef = useRef(null);
  const [cardHeaderColors, setCardHeaderColors] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeOrder, setActiveOrder] = useState(null);
  const [changedTotals, setChangedTotals] = useState({});
  const [capturingPayment, setCapturingPayment] = useState({});
  const [processingOrders, setProcessingOrders] = useState({});
  const [firebaseUid, setFirebaseUid] = useState(null);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("other");
  const [additionalDetails, setAdditionalDetails] = useState("");

  const handleChangedTotalChange = (orderId, value) => {
    setChangedTotals((prev) => ({ ...prev, [orderId]: value }));
  };
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setFirebaseUid(user.uid);
      } else {
        setFirebaseUid(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const orderRefs = useRef(
    orders.reduce((acc, order) => {
      acc[order.id] = React.createRef();
      return acc;
    }, {})
  );
  const scrollToOrder = (id) => {
    setActiveOrder(id);
    orderRefs.current[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const pollOrders = async () => {
    if (!firebaseUid) return;

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/orders/poll/${firebaseUid}?lastPoll=${encodeURIComponent(
          lastPollTimeRef.current
        )}`
      );
      const data = await response.json();
      console.log(data);
      if (data.orders && data.orders.length > 0) {
        const newOrders = data.orders.filter(
          (order) =>
            !printedOrders.has(order._id) &&
            !orderIdsRef.current.includes(order._id)
        );

        if (newOrders.length > 0) {
          const sound = new Audio(soundBite); // Replace with actual sound file path
          sound.play();
        }

        setOrders(data.orders);
        orderIdsRef.current = data.orders.map((order) => order._id);
        lastPollTimeRef.current = new Date().toISOString();
      }
    } catch (err) {
      console.error("Failed to poll new orders:", err);
    }
  };
  useEffect(() => {
    let intervalId;

    if (firebaseUid) {
      pollOrders(); // Initial poll
      intervalId = setInterval(pollOrders, 300000); // 5 minutes
    }

    return () => clearInterval(intervalId);
  }, [firebaseUid]);

  const acceptAndCapturePayment = async (order) => {
    if (
      !window.confirm(
        "Are you sure you want to accept this order and capture the payment?"
      )
    )
      return;

    setProcessingOrders((prev) => ({ ...prev, [order._id]: true }));
    console.log(order.payment_intent_id);

    try {
      const initialAmount = order.totalCost;

      const editedAmount = parseFloat(
        changedTotals[order._id] || initialAmount
      );

      // Check if the edited amount is within the $10 buffer limit
      const maxAllowedAmount = initialAmount + 10;
      if (editedAmount > maxAllowedAmount) {
        throw new Error(
          `The final amount exceeds the allowed buffer limit of $10. Max allowed: $${maxAllowedAmount}`
        );
      }

      const finalAmount =
        editedAmount + order.processingFee + order.deliveryFee;
      console.log(finalAmount);
      const payload = {
        paymentIntentId: order.payment_intent_id,
        finalAmount: finalAmount,
      };
      console.log("Payment capture payload:", payload);

      const captureResponse = await fetch(
        `${process.env.REACT_APP_ASSET_URL}/capture-payment`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!captureResponse.ok) {
        const errorText = await captureResponse.text();
        throw new Error(
          `Failed to capture payment: ${captureResponse.status} ${errorText}`
        );
      }

      const captureResult = await captureResponse.json();
      if (!captureResult.success) throw new Error(captureResult.error);

      alert(
        `Payment of $${captureResult.capturedAmount} captured successfully.`
      );

      await updateOrderStatus(order._id, "accepted", finalAmount);
    } catch (err) {
      console.error(`Failed to capture payment: ${err.message}`);
      alert(`Failed to capture payment: ${err.message}`);
    } finally {
      setProcessingOrders((prev) => ({ ...prev, [order._id]: false }));
    }
  };

  const updateOrderStatus = async (orderId, newStatus, chargedTotal = null) => {
    try {
      const payload = { orderStatus: newStatus };
      if (chargedTotal !== null) {
        payload.chargedTotal = chargedTotal;
      }

      const response = await fetch(
        `${process.env.REACT_APP_ASSET_URL}/api/orders/${orderId}/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            // Add any necessary authentication headers
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update order status: ${response.status}`);
      }

      const updatedOrder = await response.json();
      // Update the local state with the new order status and charged total
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === orderId
            ? {
                ...order,
                orderStatus: newStatus,
                chargedTotal: chargedTotal || order.chargedTotal,
              }
            : order
        )
      );

      console.log(`Order ${orderId} status updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating order status:", error);
      alert(`Failed to update order status: ${error.message}`);
    }
  };
  const rejectOrder = async (orderId, reason, details) => {
    setProcessingOrders((prev) => ({ ...prev, [orderId]: true }));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/reject`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ reason, details }), // Include reason and details in the request
        }
      );

      if (!response.ok) throw new Error("Failed to reject order");

      const result = await response.json();

      // Update the local state
      setOrders(
        orders.map((o) =>
          o._id === orderId ? { ...o, orderStatus: "Rejected" } : o
        )
      );

      alert("Order rejected successfully.");
      pollOrders();
    } catch (err) {
      console.error(`Failed to reject order: ${err.message}`);
      alert(`Failed to reject order: ${err.message}`);
    } finally {
      setProcessingOrders((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  const moveOrderToDone = async (orderId) => {
    if (!window.confirm("Are you sure you want to mark this order as done?"))
      return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/moveToDone`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Could not move order to done.");
      }

      setOrders(orders.filter((order) => order._id !== orderId));
      console.log("orders", orders);
    } catch (err) {
      console.error("Failed to move order to done:", err);
    }
  };
  const nonDeliveryOrNotReadyOrders = orders.filter(
    (order) => order.orderType !== "delivery" || order.orderStatus !== "ready"
  );

  // Filter orders for the "Ready for Delivery" tab
  const readyForDeliveryOrders = orders.filter(
    (order) => order.orderType === "delivery" && order.orderStatus === "ready"
  );

  return (
    <div>
      <div className="mt-3 ml-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to="/st/dashboard">Dashboard</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <BreadcrumbPage>Orders</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="text-start text-2xl font-bold">Orders</div>{" "}
      </div>
      <Tabs defaultValue="orders" className="m-4 ">
        <TabsList className="grid  grid-cols-2">
          <TabsTrigger value="orders">Orders</TabsTrigger>
          <TabsTrigger value="ready">Ready for Delivery</TabsTrigger>
        </TabsList>
        <TabsContent value="orders">
          <div className="grid gap-6 md:gap-8 px-4 md:px-6 py-8">
            <div className="grid gap-4">
              {nonDeliveryOrNotReadyOrders.map((order) => (
                <Card key={order.id}>
                  <CardContent className="grid gap-4 m-2">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <TruckIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                        <span className="font-medium">
                          Tracking #: {order.trackingNumber}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button variant="outline" size="sm">
                          {order.orderStatus}
                        </Button>
                        {order.orderStatus === "accepted" && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              updateOrderStatus(order._id, "ready")
                            }
                          >
                            Ready
                          </Button>
                        )}
                        {order.orderStatus === "ready" && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => moveOrderToDone(order._id)}
                          >
                            Done
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="text-lg text-black">
                        {order.orderType === "delivery" ? (
                          <>
                            Delivery:{" "}
                            {order.deliveryInfo?.deliveryTime || "N/A"}
                          </>
                        ) : (
                          <>Pickup: {order.pickupTime || "N/A"}</>
                        )}
                      </div>

                      <div className="text-lg text-black ">
                        Customer: {order.user.firstName}
                      </div>
                      {/* <div className="flex items-center gap-2">
                  <Button variant="outline" size="sm">
                    <PrinterIcon className="w-4 h-4 mr-2" />
                    Print
                  </Button>
                  <Button variant="outline" size="sm">
                    <DownloadIcon className="w-4 h-4 mr-2" />
                    Export
                  </Button>
                </div> */}
                    </div>

                    <div className="border rounded-lg overflow-hidden">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="w-[32px]">
                              <Checkbox id={`select-all-${order.id}`} />
                            </TableHead>
                            <TableHead>Product</TableHead>
                            <TableHead>Quantity</TableHead>
                            <TableHead className="text-right">Price</TableHead>
                          </TableRow>
                        </TableHeader>
                        {/* order contents */}
                        <TableBody>
                          {order.products.map((products) => (
                            <TableRow key={products.id}>
                              <TableCell>
                                <Checkbox id={`select-${products.id}`} />
                              </TableCell>
                              <TableCell>
                                <div className="flex items-center gap-2">
                                  <img
                                    src={products.product.imageUrls[0]}
                                    alt={products.product.title}
                                    width={40}
                                    height={40}
                                    className="rounded-md"
                                  />
                                  <div>{products.product.title}</div>
                                </div>
                              </TableCell>
                              <TableCell className="text-left">
                                {products.quantity}
                              </TableCell>
                              <TableCell className="text-right">
                                <div>${products.product.price}</div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    {/* order summary */}
                    <div className="grid md:grid-cols-2 gap-6">
                      <Card>
                        <CardHeader>
                          <CardTitle>Order Summary</CardTitle>
                        </CardHeader>
                        <CardContent className="grid gap-4">
                          <div className="flex items-center justify-between">
                            <div>Subtotal</div>
                            <div>${order.totalCost}</div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div>Discount</div>
                            {/* <div>-${order.discount.toFixed(2)}</div> */}
                          </div>
                          <Separator />
                          <div className="flex items-center justify-between font-medium">
                            <div>Quoted Total:</div>
                            <div>${order.totalCost}</div>
                          </div>
                          <div className="flex items-center justify-between font-medium">
                            <div>Charged Total:</div>
                            <div>
                              {order.orderStatus === "received" ? (
                                <Input
                                  id={`charged-${order._id}`}
                                  value={
                                    changedTotals[order._id] || order.totalCost
                                  }
                                  onChange={(e) =>
                                    handleChangedTotalChange(
                                      order._id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter final amount"
                                  required
                                  className="text-end"
                                />
                              ) : (
                                `$${order.chargedTotal}`
                              )}
                            </div>
                          </div>
                        </CardContent>

                        {order.orderStatus === "received" && (
                          <CardFooter className="flex items-center gap-2">
                            <Dialog
                              open={isRejectDialogOpen}
                              onOpenChange={setIsRejectDialogOpen}
                            >
                              <DialogTrigger asChild>
                                <Button variant="destructive">
                                  Reject Order
                                </Button>
                              </DialogTrigger>
                              <DialogContent className="sm:max-w-[425px]">
                                <div className="flex flex-col gap-6 p-6">
                                  <div className="grid gap-2">
                                    <DialogTitle>
                                      Reason for Rejecting Order
                                    </DialogTitle>
                                    <DialogDescription>
                                      Please select the reason for rejecting
                                      this order.
                                    </DialogDescription>
                                  </div>
                                  <div className="grid gap-2">
                                    <RadioGroup
                                      value={selectedReason}
                                      onValueChange={setSelectedReason}
                                      className="grid gap-2"
                                    >
                                      <Label
                                        htmlFor="out-of-stock"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="out-of-stock"
                                          value="out-of-stock"
                                        />
                                        Out of Stock Items
                                      </Label>
                                      <Label
                                        htmlFor="invalid-payment"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="invalid-payment"
                                          value="invalid-payment"
                                        />
                                        Invalid Payment Info
                                      </Label>
                                      <Label
                                        htmlFor="suspicious"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="suspicious"
                                          value="suspicious"
                                        />
                                        Suspicious
                                      </Label>
                                      <Label
                                        htmlFor="delivery-restriction"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="delivery-restriction"
                                          value="delivery-restriction"
                                        />
                                        Delivery Restriction
                                      </Label>
                                      <Label
                                        htmlFor="too-late"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="too-late"
                                          value="too-late"
                                        />
                                        Too Late
                                      </Label>
                                      <Label
                                        htmlFor="other"
                                        className="flex items-center gap-2 cursor-pointer [&:has([data-state=checked])]:bg-muted rounded-md px-3 py-2"
                                      >
                                        <RadioGroupItem
                                          id="other"
                                          value="other"
                                        />
                                        Other
                                      </Label>
                                    </RadioGroup>
                                    <Textarea
                                      placeholder="Provide additional details (optional)"
                                      value={additionalDetails}
                                      onChange={(e) =>
                                        setAdditionalDetails(e.target.value)
                                      }
                                      className="p-3 bg-muted rounded-md"
                                    />
                                  </div>
                                </div>
                                <DialogFooter>
                                  <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => setIsRejectDialogOpen(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      rejectOrder(
                                        order._id,
                                        selectedReason,
                                        additionalDetails
                                      );
                                      setIsRejectDialogOpen(false);
                                    }}
                                  >
                                    Reject Order
                                  </Button>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>

                            <button
                              className="bg-green-500 text-white hover:bg-green-800 py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                              onClick={() => acceptAndCapturePayment(order)}
                              disabled={processingOrders[order._id]}
                            >
                              {processingOrders[order._id]
                                ? "Processing..."
                                : "Accept Order"}
                            </button>
                          </CardFooter>
                        )}
                      </Card>
                      <Card>
                        <CardHeader>
                          <CardTitle>Order Notes</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <Textarea
                            placeholder="Add notes about the order..."
                            className="w-full"
                            disable
                            value={order.notes}
                          />
                        </CardContent>
                      </Card>
                    </div>
                    <Card>
                      {/* customer info */}
                      <CardHeader>
                        <CardTitle>Customer Information</CardTitle>
                      </CardHeader>
                      <CardContent className="grid gap-4">
                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="grid gap-1">
                            <Label htmlFor={`last-name-${order.id}`}>
                              Last Name
                            </Label>
                            <Input
                              id={`last-name-${order.id}`}
                              defaultValue={order.user.lastName}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`last-name-${order.id}`}>
                              First Name
                            </Label>
                            <Input
                              id={`first-name-${order.id}`}
                              defaultValue={order.user.firstName}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`phone-${order.id}`}>Phone</Label>
                            <Input
                              id={`phone-${order.id}`}
                              defaultValue={order.user.phoneNumber}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`email-${order.id}`}>Email</Label>
                            <Input
                              id={`email-${order.id}`}
                              defaultValue={order.user.email}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`opt-${order.id}`}>OTP:</Label>
                            <div className="border rounded p-1">
                              {order.otp}
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </TabsContent>
        <TabsContent value="ready">
          <div className="grid gap-6 md:gap-8 px-4 md:px-6 py-8">
            <div className="grid gap-4">
              {readyForDeliveryOrders.map((order) => (
                <Card key={order.id}>
                  <CardContent className="grid gap-4 m-2">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <TruckIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                        <span className="font-medium">
                          Tracking #: {order.trackingNumber}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button variant="outline" size="sm">
                          {order.orderStatus}
                        </Button>
                        {order.orderStatus === "accepted" && (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              updateOrderStatus(order._id, "ready")
                            }
                          >
                            Ready
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="text-lg text-black">
                        {order.orderType === "delivery" ? (
                          <>
                            Delivery:{" "}
                            {order.deliveryInfo?.deliveryTime || "N/A"}
                          </>
                        ) : (
                          <>Pickup: {order.pickupTime || "N/A"}</>
                        )}
                      </div>

                      <div className="text-lg text-black ">
                        Customer: {order.user.firstName}
                      </div>
                      {/* <div className="flex items-center gap-2">
                  <Button variant="outline" size="sm">
                    <PrinterIcon className="w-4 h-4 mr-2" />
                    Print
                  </Button>
                  <Button variant="outline" size="sm">
                    <DownloadIcon className="w-4 h-4 mr-2" />
                    Export
                  </Button>
                </div> */}
                    </div>

                    <div className="border rounded-lg overflow-hidden">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="w-[32px]">
                              <Checkbox id={`select-all-${order.id}`} />
                            </TableHead>
                            <TableHead>Product</TableHead>
                            <TableHead>Quantity</TableHead>
                            <TableHead className="text-right">Price</TableHead>
                          </TableRow>
                        </TableHeader>
                        {/* order contents */}
                        <TableBody>
                          {order.products.map((products) => (
                            <TableRow key={products.id}>
                              <TableCell>
                                <Checkbox id={`select-${products.id}`} />
                              </TableCell>
                              <TableCell>
                                <div className="flex items-center gap-2">
                                  <img
                                    src={products.product.imageUrls[0]}
                                    alt={products.product.title}
                                    width={40}
                                    height={40}
                                    className="rounded-md"
                                  />
                                  <div>{products.product.title}</div>
                                </div>
                              </TableCell>
                              <TableCell className="text-left">
                                {products.quantity}
                              </TableCell>
                              <TableCell className="text-right">
                                <div>${products.product.price}</div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    {/* order summary */}
                    <div className="grid md:grid-cols-2 gap-6">
                      <Card>
                        <CardHeader>
                          <CardTitle>Order Summary</CardTitle>
                        </CardHeader>
                        <CardContent className="grid gap-4">
                          <div className="flex items-center justify-between">
                            <div>Subtotal</div>
                            <div>${order.totalCost}</div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div>Discount</div>
                            {/* <div>-${order.discount.toFixed(2)}</div> */}
                          </div>
                          <Separator />
                          <div className="flex items-center justify-between font-medium">
                            <div>Quoted Total:</div>
                            <div>${order.totalCost}</div>
                          </div>
                          <div className="flex items-center justify-between font-medium">
                            <div>Charged Total:</div>
                            <div>
                              {order.orderStatus === "received" ? (
                                <Input
                                  id={`charged-${order._id}`}
                                  value={
                                    changedTotals[order._id] || order.totalCost
                                  }
                                  onChange={(e) =>
                                    handleChangedTotalChange(
                                      order._id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter final amount"
                                  required
                                  className="text-end"
                                />
                              ) : (
                                `$${order.chargedTotal}`
                              )}
                            </div>
                          </div>
                        </CardContent>
                        {order.orderStatus === "received" && (
                          <CardFooter className="flex items-center gap-2">
                            <button
                              className="border border-red-500 text-red-500 hover:bg-red-800 hover:text-white py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                              onClick={() => rejectOrder(order._id)}
                              disabled={processingOrders[order._id]}
                            >
                              {processingOrders[order._id]
                                ? "Processing..."
                                : "Reject Order"}
                            </button>
                            <button
                              className="bg-green-500 text-white hover:bg-green-800 py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                              onClick={() => acceptAndCapturePayment(order)}
                              disabled={processingOrders[order._id]}
                            >
                              {processingOrders[order._id]
                                ? "Processing..."
                                : "Accept Order"}
                            </button>
                          </CardFooter>
                        )}
                      </Card>
                      <Card>
                        <CardHeader>
                          <CardTitle>Order Notes</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <Textarea
                            placeholder="Add notes about the order..."
                            className="w-full"
                            disable
                            value={order.notes}
                          />
                        </CardContent>
                      </Card>
                    </div>
                    <Card>
                      {/* customer info */}
                      <CardHeader>
                        <CardTitle>Customer Information</CardTitle>
                      </CardHeader>
                      <CardContent className="grid gap-4">
                        <div className="grid md:grid-cols-2 gap-4">
                          <div className="grid gap-1">
                            <Label htmlFor={`last-name-${order.id}`}>
                              Last Name
                            </Label>
                            <Input
                              id={`last-name-${order.id}`}
                              defaultValue={order.user.lastName}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`last-name-${order.id}`}>
                              First Name
                            </Label>
                            <Input
                              id={`first-name-${order.id}`}
                              defaultValue={order.user.firstName}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`phone-${order.id}`}>Phone</Label>
                            <Input
                              id={`phone-${order.id}`}
                              defaultValue={order.user.phoneNumber}
                            />
                          </div>
                          <div className="grid gap-1">
                            <Label htmlFor={`email-${order.id}`}>Email</Label>
                            <Input
                              id={`email-${order.id}`}
                              defaultValue={order.user.email}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
export default Orders;
