import { Button } from "@/components/ui/button";
import React from "react";
import { useParams } from "react-router-dom";
import StripeLogo from "../../src/Ycomponents/stripeLogo.svg";
import { Link } from "react-router-dom";

export default function Return() {
  const { connectedAccountId } = useParams();

  return (
    <div className="container mx-auto px-4 py-12 max-w-3xl">
      <div className="text-center space-y-8">
        <div className="bg-primary text-primary-foreground py-4 rounded-lg ">
          <h2 className="text-m  font-bold tracking-tight text-primary flex items-center justify-center">
            Spockal x{" "}
            <a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center "
            >
              <img src={StripeLogo} alt="Stripe" className="h-8" />
            </a>
          </h2>{" "}
        </div>

        <div className="space-y-6">
          <h2 className="text-3xl font-bold tracking-tight text-primary">
            Details submitted
          </h2>
          <p className="text-xl text-muted-foreground">
            That's everything we need for now
          </p>

          <Button size="lg" className="mt-4 px-8 py-3">
            <Link to="/st/dashboard">Return to Dashboard</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
